import { FunctionComponent, useState } from 'react';
import { ListItem, ListItemSecondaryAction, IconButton, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import EditDialog from './editDialog';
interface Props {
  onProjectCreated: () => void;
}

const ActionBar: FunctionComponent<Props> = (props) => {
  const { onProjectCreated } = props;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const classes = useStyles();

  return (
    <ListItem className={classes.item}>
      <EditDialog onRequestDone={onProjectCreated} open={dialogIsOpen} onClose={() => setDialogIsOpen(false)} />
      <ListItemSecondaryAction>
        <IconButton color="primary" edge="end" aria-label="comments" onClick={() => setDialogIsOpen(true)}>
          <Add />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  item: {
    marginTop: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));

export default ActionBar;
