import { ChangeEvent } from 'react';
import { FieldType } from '../../../domain/flags/types';
import Checkbox from './components/Checkbox';
import Input from './components/Input';
import Select from './components/Select';
import SelectMany from './components/SelectMany';

interface HookProps {
  type: FieldType;
  onChange: (value: any) => void;
  value: any;
  disabled?: boolean;
  options?: Array<{ value: string; label: string }>;
  error?: string;
}

export interface MainProps<ValueType, ChangeType> {
  value: ValueType;
  onChange: (value: ChangeType) => void;
  disabled: boolean;
  error?: string;
}

export const useFieldComponent = ({ type, value, onChange, options, error, disabled = false }: HookProps) => {
  const defaultProps = {
    value,
    disabled,
    error,
  };
  if (type === FieldType.NUMBER) {
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value);
    return { Component: <Input onChange={handleChange} {...defaultProps} /> };
  }
  if (type === FieldType.BOOLEAN) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);
    return { Component: <Checkbox onChange={handleChange} {...defaultProps} /> };
  }
  if (type === FieldType.SELECT) {
    const handleChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => onChange(e.target.value);
    return { Component: <Select options={options!} onChange={handleChange} {...defaultProps} /> };
  }
  if (type === FieldType.SELECT_MANY) {
    const handleChange = (e: any) => onChange(e);
    return { Component: <SelectMany disabled={disabled} options={options!} value={value} onChange={handleChange} /> };
  }

  const handleChange = (value: any) => onChange(value.map((c: any) => c.value));
  const selectValue = options!.filter((c) => (value || []).includes(c.value));
  return { Component: <SelectMany disabled={disabled} options={options!} value={selectValue} onChange={handleChange} /> };
};
