import { FeatureFlags } from "../flags/types";
import { UserRegType, UserType } from "../segment/types";

export interface Cohort {
  allCountries: boolean;
  countryCodes: string[];
}

export interface Group {
  id?: string;
  name: string;
  weight: number;
  description: string;
  flags?: Partial<FeatureFlags>;
}

export interface Segment {
  languages: string[];
  currencies: string[];
  userRegType: UserRegType;
  isActivated: boolean;
  userType: UserType;
}

export interface Test {
  id: string;
  uid: string;
  name: string;
  status: 'initial' | 'active';
  cohort: Cohort;
  groups: Group[];
  winGroupID: string;
  createdAt: string;
  startedAt: string;
  scheduledStartTime?: string | null;
  finishedAt: string;
  userType: UserType;
  initialAppVersion?: string;
  type?: TestType;
  storeRollout: boolean;
  storeRolloutTrafficShare?: null | number;
  storeRolloutPreviousAppVersion?: null | string;
  segment: null | Segment;
}

export enum TestType {
  MANUAL = 0,
  AUTO = 1,
  FEATURE = 2,
}
