import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import GroupView from './groupView';
import ItemMenu from './itemMenu';

import { Test, TestType } from '../../../domain/tests';
import { Country } from '../../../domain/countries';
import format from 'date-fns/format';
import { getStatistic, StatisticResult } from '../../../domain/tests';
import { parse } from 'date-fns';
import { editStartDate } from '../../../domain/tests/editStartDate';
import { UserType } from '../../../domain/segment/types';

interface Props {
  test: Test;
  projectUID: string;
  countries: Country[];
  onEditTest: () => void;
  onTestEdited: () => void;
}

const dateFormat = 'yyyy-MM-dd HH:mm';

const AbTest: FunctionComponent<Props> = (props) => {
  const { test, projectUID, countries, onEditTest, onTestEdited } = props;

  const classes = useStyles();
  const [statistic, setStatistic] = useState<StatisticResult[] | null>(null);
  const [statisticLoaded, setLoaded] = useState(false);
  const [startedAt, setStartedAt] = useState<string | undefined>();
  const [error, setError] = useState(false);
  const editable = test.type !== TestType.AUTO;

  const countryNames = test.cohort.allCountries
    ? ['All countries']
    : countries.filter((c) => test.cohort.countryCodes.includes(c.countryCode)).map((c) => c.name);

  useEffect(() => {
    (async () => {
      if (statisticLoaded || !test.startedAt) {
        return;
      }

      setStartedAt(format(new Date(test.startedAt), dateFormat));
      const statistic = await getStatistic(projectUID, test.uid);
      setLoaded(true);
      if (statistic) {
        setStatistic(statistic);
      }
    })();
  }, [test.startedAt]);

  const handleSaveTestStartDate = async (value: string) => {
    try {
      const date = parse(value, dateFormat, new Date()).toISOString();
      await editStartDate(projectUID, test.uid, { startedAt: date });
      onTestEdited();
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Paper className={classes.paper} elevation={4}>
      <div className={classes.controlContainer}>
        <Typography component="h4" variant="h5">
          {test.name}
        </Typography>
        {editable && <ItemMenu projectUID={projectUID} test={test} onEditTest={onEditTest} onTestEdited={onTestEdited} />}
      </div>
      <Typography component="h5" variant="subtitle2">
        {countryNames.join(', ')}
      </Typography>
      {test.userType !== UserType.All ? (
        <Typography component="h5" variant="subtitle2">
          {`${test.userType === UserType.NEW ? 'Starting from' : 'Until'} version ${test.initialAppVersion}`}
        </Typography>
      ) : undefined}
      <Typography component="h5" variant="caption">
        {`https://${window.location.host}/api/v1/projects/${projectUID}/tests/${test.uid}?uid=<ADD_ID>`}
      </Typography>
      {test.groups.map((g, i) => (
        <GroupView
          key={i}
          projectUID={projectUID}
          group={g}
          isTestNotFinished={editable && Boolean(test.startedAt) && !Boolean(test.finishedAt)}
          testId={test.uid}
          isWon={test.winGroupID === g.id}
          participants={statistic ? statistic.find((s) => s.name == g.name)?.count ?? 0 : 0}
          onTestEdited={onTestEdited}
        />
      ))}
      <hr />
      {test.createdAt ? (
        <Typography component="span" variant="body2">
          Created: {format(new Date(test.createdAt), dateFormat)}
        </Typography>
      ) : null}
      <br />
      {test.scheduledStartTime && !startedAt ? (
        <Typography component="span" variant="body2">
          Starts at : {format(new Date(test.scheduledStartTime), dateFormat)}
        </Typography>
      ) : null}
      <br />
      {startedAt ? (
        <div className={`${classes.controlContainer} ${classes.widthSize}`}>
          <Typography component="span" variant="body2">
            Started:
          </Typography>
          <TextField
            value={startedAt}
            onChange={(e) => {
              setStartedAt(e.target.value);
              setError(false);
            }}
            error={error}
            disabled={!editable}
          />
          {editable && (
            <Button size="small" variant="contained" color="primary" onClick={() => handleSaveTestStartDate(startedAt)}>
              Save
            </Button>
          )}
        </div>
      ) : null}
      <br />
      {test.finishedAt ? (
        <Typography component="span" variant="body2">
          Finished: {format(new Date(test.finishedAt), dateFormat)}
        </Typography>
      ) : null}
    </Paper>
  );
};

const useStyles = makeStyles({
  paper: {
    padding: '1rem',
    marginBottom: '2rem',
  },
  testName: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  widthSize: {
    maxWidth: '325px',
  },
});

export default AbTest;
