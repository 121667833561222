export enum UserRegType {
  NEW_REG = 'new_reg',
  PLATFORM_AUTH = 'platform_auth',
  REREG = 'rereg',
}

export enum UserType {
  OLD = 'old',
  NEW = 'new',
  All = 'all',
}

export enum SegmentName {
  languages = 'languages',
  currencies = 'currencies',
  userRegType = 'userRegType',
  isActivated = 'isActivated',
  userType = 'userType',
}
