import { Error, request } from '../api';
import { Test } from './types';

interface EditTestStartDateDTO {
  startedAt: string;
}

export async function editStartDate(projectUID: string, testUID: string, editData: EditTestStartDateDTO): Promise<Test & Error> {
    const { data } = await request(`/admin/projects/${projectUID}/tests/${testUID}/edit`, { method: 'PUT', data: editData });
    return data;
}