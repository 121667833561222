import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, makeStyles, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Project, getProject } from '../../domain/projects';
import { Test, getTests, TestType, updateFlags } from '../../domain/tests';
import { Country, getCountries } from '../../domain/countries';
import CreateAbTestDialog from './create/createAbTestDialog';
import AbTest from './list/abTest';
import FieldList from '../flags/fields/list';
import { FeatureFlags, FieldType } from '../../domain/flags/types';
import consts from '../../common/consts';
import { getDefaultFlags } from '../../domain/flags/getDefault';
import TestTypesTabs from '../../components/header/tabs/testTypes';
import { validateFlags } from '../flags/validateFlags';

export interface RouterParams {
  uid: string;
  type: string;
}

interface TestDialogData {
  isOpen: boolean;
  editTest?: Test;
}

const ProjectDetails: FunctionComponent = () => {
  const params = useParams<RouterParams>();
  const classes = useStyles();
  const isFeatureFlagsTest = params.type === 'ff';
  const [project, setProject] = useState<Project>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [tests, setTests] = useState<Test[]>([]);
  const [testDialogData, setTestDialogData] = useState<TestDialogData>({ isOpen: false });
  const [showDefaultFlagsSettings, setShowDefaultFlagsSettings] = useState<boolean>(false);
  const [errors, setErrors] = useState({});

  const [defaultFlags, setDefaultFlags] = useState<FeatureFlags>({} as FeatureFlags);
  const updateDefaultFlag = (field: string, value: any) => {
    setDefaultFlags((prev) => {
      const newFlags = { ...prev, [field]: value };
      return newFlags;
    });
  };

  useEffect(() => {
    const errors = validateFlags(defaultFlags);
    setErrors(errors);
  }, [defaultFlags])

  const loadNewTests = useCallback(async () => {
    const tests = await getTests(params.uid);
    setTests(tests);
  }, [params, setTests]);

  const loadFeatureFlags = async () => {
    const defaultFlags = await getDefaultFlags(params.uid);
    setDefaultFlags(defaultFlags);
  }

  const updateFeatureFlags = async () => {
    const flags = await updateFlags(params.uid, defaultFlags);
    setDefaultFlags(flags);
  }

  useEffect(() => {
    (async () => {
      const projects = await getProject(params.uid);
      const countries = await getCountries();
      const defaultFlags = await getDefaultFlags(params.uid);
      await loadNewTests();
      setProject(projects);
      setCountries(countries);
      setDefaultFlags(defaultFlags);
    })();
  }, []);

  const filterTest = (test: Test) => {
    if (params.type === 'auto') {
      return test.type === TestType.AUTO;
    }
    if (params.type === 'ff') {
      return test.type === TestType.FEATURE;
    }
    return test.type === TestType.MANUAL;
  };

  if (!project) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <TestTypesTabs />
        <div className={classes.container}>
          <Typography variant="h5">{project.name}</Typography>
        </div>
        {isFeatureFlagsTest && (
          <>
            <div className={classes.settings}>
              <FormControlLabel
                label={'Show default Feature Flags settings'}
                control={
                  <Checkbox color="primary" checked={showDefaultFlagsSettings} onChange={(e) => setShowDefaultFlagsSettings(e.target.checked)} />
                }
              />
              {showDefaultFlagsSettings && (
                <Button disabled={Object.keys(errors).length !== 0} onClick={updateFeatureFlags} variant="contained" color="primary">
                  Save
                </Button>
              )}
            </div>

            {showDefaultFlagsSettings && Object.keys(defaultFlags).length !== 0 && (
              <FieldList errors={errors} label="Default fields" fieldsList={consts.FEATURE_FIELDS} update={updateDefaultFlag} values={defaultFlags} />
            )}
          </>
        )}
        <Button variant="contained" color="primary" className={classes.createTestButton} onClick={() => setTestDialogData({ isOpen: true })}>
          Create test
        </Button>
        {testDialogData.isOpen ? (
          <CreateAbTestDialog
            open={testDialogData.isOpen}
            countries={countries}
            projectUID={project.uid}
            onClose={() => setTestDialogData({ isOpen: false })}
            onSavingDone={() => {
              setTestDialogData({ isOpen: false });
              loadNewTests();
            }}
            testData={testDialogData.editTest}
            withFeatureFlags={isFeatureFlagsTest}
            defaultFlags={defaultFlags}
          />
        ) : undefined}
        {tests
          .filter((t) => filterTest(t))
          .map((t, i) => (
            <AbTest
              key={t.id}
              test={t}
              projectUID={project.uid}
              countries={countries}
              onEditTest={() => setTestDialogData({ isOpen: true, editTest: t })}
              onTestEdited={() => {
                loadNewTests();
                loadFeatureFlags();
              }}
            />
          ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '16px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  createTestButton: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default ProjectDetails;
