import { FunctionComponent } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface Props {
  name: string;
  setName: (n: string) => void;
}

const TestInfo: FunctionComponent<Props> = (props) => {
  const { name, setName } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.typography} variant="subtitle1" component="h4">
        Test name *
      </Typography>
      <TextField variant="outlined" value={name} onChange={(e) => setName(e.target.value as string)} name="testName" required fullWidth />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  typography: {},
});

export default TestInfo;
