import { FunctionComponent } from 'react';
import Header from '../components/header';
import { Container, makeStyles } from '@material-ui/core';
import ProjectDetails from '../features/projectDetails';

const ProjectPage: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <Container className={classes.body}>
        <ProjectDetails />
      </Container>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: 1000,
  },
}));

export default ProjectPage;
