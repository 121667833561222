import { Error, request } from '../api';
import { Test } from './types';

interface EditTestStatusRequest {
  status: 'initial' | 'active' | 'finished' | 'deleted';
  winGroupID?: string;
}

export async function editTestStatus(projectUID: string, testUID: string, editData: EditTestStatusRequest): Promise<Test & Error> {
  const { data } = await request(`/admin/projects/${projectUID}/tests/${testUID}/status`, { method: 'POST', data: editData });
  return data;
}
