import { request, Error } from '../api';
import { Project } from './types';

export async function editProject(
  uid: string,
  vaultProjectName: string,
  vaultProjectType: string,
): Promise<Project & Error> {
  const { data } = await request(`/admin/projects/${uid}`, { method: 'PUT', data: { uid, vaultProjectName, vaultProjectType } });
  return data;
}