import { request } from '../api';
import { Test } from './types';

export interface StatisticResult {
  name: string;
  count: number;
}

export async function getStatistic(projectUID: string, testID: string): Promise<StatisticResult[]> {
  const { data } = await request(`/admin/projects/${projectUID}/tests/${testID}/statistic`, { method: 'GET' });
  return data;
}
