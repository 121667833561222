import { Error, request } from '../api';
import { UserType } from '../segment/types';
import { Test } from './types';
import { Group, Cohort } from './types';

interface CreateTestRequest {
    name: string;
    cohort: Cohort;
    groups: Group[];
    userType: UserType;
    initialAppVersion?: string;
    storeRollout: boolean;
    storeRolloutPreviousAppVersion?: string | null;
    storeRolloutTrafficShare?: number | null;
}

export async function editTest(projectUID: string, testUID: string, test: CreateTestRequest): Promise<Test & Error> {
    const { data } = await request(`/admin/projects/${projectUID}/tests/${testUID}`, { method: 'PUT', data: test });
    return data;
}