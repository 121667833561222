import { FunctionComponent, useState } from 'react';
import { IconButton, Dialog, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import { PlayArrow, DeleteForever, Edit, Pause } from '@material-ui/icons';
import { Test, editTestStatus, releaseTest } from '../../../domain/tests';
import { VariantType, useSnackbar } from 'notistack';

interface Props {
  test: Test;
  projectUID: string;
  onEditTest: () => void;
  onTestEdited: () => void;
}

const ItemMenu: FunctionComponent<Props> = (props) => {
  const { projectUID, test, onEditTest, onTestEdited } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [releaseDialogIsOpen, setReleaseDialogIsOpen] = useState(false);

  const handleStartTest = async () => {
    await editTestStatus(projectUID, test.uid, { status: 'active' });
    onTestEdited();
  };

  const handlePauseTest = async () => {
    await editTestStatus(projectUID, test.uid, { status: 'initial' });
    onTestEdited();
  };

  const handleEditTest = () => {
    onEditTest();
  };

  const handleDeleteTest = async () => {
    await editTestStatus(projectUID, test.uid, { status: 'deleted' });
    onTestEdited();
  };

  const handleReleaseTest = async () => {
    const response = await releaseTest(projectUID, test.uid);
    if (response.statusCode === 409) {
      enqueueSnackbar('Test is already exist on production!', { variant: 'error' });
    } else if (response.message) {
      const message = Array.isArray(response.message) ? response.message.join(', ') : response.message;
      enqueueSnackbar(message, { variant: 'error' });
    } else {
      enqueueSnackbar('Test released', { variant: 'success' });
    }
    setReleaseDialogIsOpen(false);
  };

  return (
    <div>
      {test.status != 'active' ? (
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" color="primary" onClick={handleStartTest}>
          <PlayArrow />
        </IconButton>
      ) : undefined}
      {test.status != 'active' ? (
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleEditTest}>
          <Edit />
        </IconButton>
      ) : undefined}
      {test.status == 'active' ? (
        <Button size="small" variant="outlined" color="primary" style={{ marginRight: '1rem' }} onClick={() => setReleaseDialogIsOpen(true)}>
          Release
        </Button>
      ) : undefined}
      {test.status == 'active' ? (
        <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" color="primary" onClick={handlePauseTest}>
          <Pause />
        </IconButton>
      ) : undefined}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="secondary"
        onClick={() => (test.winGroupID ? handleDeleteTest() : alert(`Unable to delete test - Winner group not chosen`))}
      >
        <DeleteForever />
      </IconButton>

      <Dialog onClose={() => setDeleteDialogIsOpen(false)} open={deleteDialogIsOpen}>
        <DialogContent dividers>
          <Typography gutterBottom>Delete this test?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteTest} color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setReleaseDialogIsOpen(false)} open={releaseDialogIsOpen}>
        <DialogContent dividers>
          <Typography color="secondary" gutterBottom>
            Releasing this test will affect PRODUCTION scope. This action cannot be undone. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleReleaseTest} color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ItemMenu;
