import { FunctionComponent, useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions, TextField, makeStyles, Typography } from '@material-ui/core';
import { Project, editProject, createProject } from '../../domain/projects';
import slugify from 'slugify';

interface Props {
  project?: Project;
  open: boolean;
  onClose: () => void;
  onRequestDone: () => void;
}

const EditDialog: FunctionComponent<Props> = (props) => {
  const { onClose, onRequestDone, open, project } = props;

  const classes = useStyles();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState(project?.name || '');

  const [vaultProjectName, setVaultProjectName] = useState(project?.vaultProjectName || '');
  const [vaultProjectType, setVaultProjectType] = useState(project?.vaultProjectType || '');

  const handleEditProject = async () => {
    setError(false);
    setErrorMessage('');
    if (!name) {
      setError(true);
      return;
    }
    let response;
    if (project) {
      response = await editProject(project.uid, vaultProjectName, vaultProjectType);
    } else {
      response = await createProject(slugify(name), name, vaultProjectName, vaultProjectType);
    }
    if (response?.statusCode) {
      const message = Array.isArray(response.message) ? response.message.join(', ') : response.message;
      setErrorMessage(response.statusCode === 409 ? 'Project name already exist' : message);
      setError(true);
      return;
    }
    onRequestDone();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent className={classes.container} dividers>
        <TextField
          label="Project name *"
          helperText={slugify(name)}
          value={name}
          inputProps={{ maxLength: 120 }}
          onChange={(e) => setName(e.target.value)}
          className={classes.input}
          error={error}
          disabled={!!project}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              handleEditProject();
            }
          }}
        />
        <Typography>
          Secrets will automatically fetched from vault
        </Typography>
        <TextField
          label="Vault project name (e.g. olympbot)"
          value={vaultProjectName}
          inputProps={{ maxLength: 200 }}
          onChange={(e) => setVaultProjectName(e.target.value)}
          error={error}
          className={classes.input}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              handleEditProject();
            }
          }}
        />
        <TextField
          label={`Vault project type (e.g. "web" or "android")`}
          value={vaultProjectType}
          inputProps={{ maxLength: 200 }}
          onChange={(e) => setVaultProjectType(e.target.value)}
          className={classes.input}
          error={error}
          onKeyPress={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              handleEditProject();
            }
          }}
        />
        <Typography color="secondary" variant="caption">
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleEditProject} color="primary">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  input: {
    marginBottom: '12px',
    minWidth: '300px',
  },
}));

export default EditDialog;
