import { Error, request } from '../api';
import { UserType } from '../segment/types';
import { Segment, Test, TestType } from './types';
import { Group, Cohort } from './types';

export interface CreateTestRequest {
    uid: string;
    name: string;
    cohort: Cohort;
    groups: Group[];
    userType: UserType;
    initialAppVersion?: string;
    storeRollout: boolean;
    storeRolloutPreviousAppVersion?: string | null;
    storeRolloutTrafficShare?: number | null;
    type?: TestType;
    segment: Partial<Segment>
    scheduledStartTime?: string | null;
}

export async function createTest(projectUID: string, test: CreateTestRequest): Promise<Test & Error> {
    const { data } = await request(`/admin/projects/${projectUID}/tests`, { method: 'POST', data: test });
    return data;
}