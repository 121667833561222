import { Checkbox, FormControlLabel, TextField, Typography, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';

interface Props {
  storeRollout: boolean;
  setStoreRollout: (rollout: boolean) => void;

  storeRolloutPreviousAppVersion: string;
  setStoreRolloutPreviousAppVersion: (version: string) => void;

  storeRolloutTrafficShare: number;
  setStoreRolloutTrafficShare: (traffic: number) => void;
}

const RolloutForm: FunctionComponent<Props> = ({
  storeRollout,
  setStoreRollout,
  storeRolloutPreviousAppVersion,
  setStoreRolloutPreviousAppVersion,
  storeRolloutTrafficShare,
  setStoreRolloutTrafficShare,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.rollout}>
      <div className={classes.rolloutItem}>
        <FormControlLabel
          label={'Store Rollout'}
          control={<Checkbox color="primary" checked={storeRollout} onChange={(e) => setStoreRollout(e.target.checked)} />}
        />
      </div>
      <div className={classes.rolloutItem}>
        {storeRollout ? (
          <div>
            <Typography variant="subtitle1" component="h4">
              Rollout previous version
            </Typography>
            <TextField
              value={storeRolloutPreviousAppVersion}
              placeholder="Rollout previous version"
              onChange={(e) => setStoreRolloutPreviousAppVersion(e.target.value as string)}
            />
          </div>
        ) : undefined}
      </div>
      <div className={classes.rolloutItem}>
        {storeRollout ? (
          <div>
            <Typography variant="subtitle1" component="h4">
              Rollout traffic share
            </Typography>
            <TextField
              value={storeRolloutTrafficShare}
              onChange={(e) => setStoreRolloutTrafficShare(Number(e.target.value))}
              name="storeRolloutTrafficShare"
              required
              fullWidth
            />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  rollout: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1rem',
  },
  rolloutItem: {
    marginRight: '1rem',
  },
});

export default RolloutForm;
