import { FunctionComponent } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import consts from '../../common/consts';

interface Props {
  group: string;
  setGroup: (group: string) => void;
}

const GroupSelect: FunctionComponent<Props> = ({ group, setGroup }) => {
  return (
    <FormControl>
      <InputLabel>Group</InputLabel>
      <Select
        value={group}
        label="group"
        onChange={(e) => setGroup(e.target.value as string)}
      >
        {
          consts.GROUP_NAMES.map((group, i) => (
            <MenuItem key={i} value={group}>
              {group}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default GroupSelect;
