import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import CohortDetails from './cohortDetails';
import TestInfo from './testInfo';
import CreateAbTestGroup from './createAbTestGroup';
import GroupWeights from './groupWeights';
import AppVersionDetails from './appVersionDetails';
import { makeStyles } from '@material-ui/styles';
import { getAppVersion } from '../../../domain/projects';
import { Test, Cohort, Group, createTest, editTest, CreateTestRequest, TestType, Segment } from '../../../domain/tests';
import { Country } from '../../../domain/countries';
import slugify from 'slugify';
import { Add, Remove } from '@material-ui/icons';
import consts from '../../../common/consts';
import RolloutForm from './rollout';
import { FeatureFlags } from '../../../domain/flags/types';
import { useParams } from 'react-router-dom';
import { RouterParams } from '..';
import FieldList from '../../flags/fields/list';
import { SegmentName, UserType } from '../../../domain/segment/types';
import StartTimeDetails from './startTimeDetails';
import { ErrorMap, validateFlags } from '../../flags/validateFlags';

interface Props {
  open: boolean;
  testData?: Test;
  countries: Country[];
  projectUID: string;
  onClose: () => void;
  onSavingDone: () => void;
  withFeatureFlags?: boolean;
  defaultFlags?: FeatureFlags;
}

const CreateAbTestDialog: FunctionComponent<Props> = (props) => {
  const { open, testData, countries, projectUID, onClose, onSavingDone, defaultFlags } = props;

  const params = useParams<RouterParams>();
  const isFeatureFlagsTest = params.type === 'ff';

  const [appVersionError, setAppVersionError] = useState<string>('');
  const [appVersion, setAppVersion] = useState(testData?.initialAppVersion || '');
  const [userType, setUserType] = useState<UserType>(testData?.userType || UserType.NEW);
  const [cohort, setCohort] = useState<Cohort>(testData?.cohort || { countryCodes: ['US'], allCountries: false });
  const [segment, setSegment] = useState<Partial<Segment>>(testData?.segment || {});
  const [scheduledStartTime, setScheduledStartTime] = useState<Date | null>(
    testData?.scheduledStartTime ? new Date(testData?.scheduledStartTime) : null,
  );
  const [flagErrors, setFlagErrors] = useState<{ [groupId: string]: ErrorMap }>({});

  const updateSegmentField = (field: string, value: any) => {
    setSegment((prev) => ({ ...prev, [field]: value }));
  };
  const addSegmentField = (field: string, value?: any) => {
    updateSegmentField(field, value);
  };
  const removeSegmentField = (field: string) => {
    const newFields = { ...segment };
    delete newFields[field as SegmentName];
    setSegment(newFields);
  };

  const [groups, setGroups] = useState<Group[]>(
    testData?.groups || [
      { name: 'A', weight: 0.5, description: '' },
      { name: 'B', weight: 0.5, description: '' },
    ],
  );

  const [name, setName] = useState(testData?.name || '');
  const [storeRollout, setStoreRollout] = useState(testData?.storeRollout || false);
  const [storeRolloutPreviousAppVersion, setStoreRolloutPreviousAppVersion] = useState<string>(testData?.storeRolloutPreviousAppVersion || '');
  const [storeRolloutTrafficShare, setStoreRolloutTrafficShare] = useState<number>(testData?.storeRolloutTrafficShare || 0);
  const [error, setError] = useState('');

  const classes = useStyles();

  const updateGroup = (group: Group, index: number) => {
    const newGroups = [...groups];
    newGroups[index] = group;
    const errors = validateFlags(group.flags as FeatureFlags);
    setFlagErrors((prev) => ({...prev, [group.name]: errors }));
    setGroups(newGroups);
  };

  const addGroup = () => {
    const weight = 1 / (groups.length + 1);
    const newGroup = { name: consts.GROUP_NAMES[groups.length], weight, description: '' };
    const newGroups = [...groups, newGroup].map((g) => ({ ...g, weight }));
    setGroups(newGroups);
  };

  const removeLastGroup = () => {
    const weight = 1 / (groups.length - 1);
    const newGroups = groups.slice(0, -1).map((g) => ({ ...g, weight }));
    setFlagErrors((prev) => {
      delete prev[groups[groups.length - 1].name];
      return prev;
    })
    setGroups(newGroups);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    if (groups[0].name === groups[1].name) {
      setError('Groups has same name');
      return true;
    }

    if (!cohort.allCountries && cohort.countryCodes.length === 0) {
      setError('Please add cohort country');
      return true;
    }

    if (userType !== UserType.All && !appVersion) {
      setError('Please input app version');
      return true;
    }

    if (isFeatureFlagsTest && (segment.languages?.length === 0 || segment.currencies?.length === 0)) {
      setError('Please add language or currency');
      return true;
    }

    const correctFlags = groups.every((group) => {
      const groupErrors = flagErrors[group.name];
      if (groupErrors === undefined) {
        return true;
      };

      return Object.keys(groupErrors).length === 0
    });
    
    if (!correctFlags) {
      setError('Incorrect parameters');
      return true;
    }

    const test: CreateTestRequest = {
      name,
      uid: slugify(name),
      cohort,
      userType: userType,
      groups,
      initialAppVersion: appVersion,
      storeRollout,
      segment,
      scheduledStartTime: scheduledStartTime?.toISOString() ?? null,
    };

    if (storeRollout) {
      test.storeRolloutTrafficShare = storeRolloutTrafficShare;
      test.storeRolloutPreviousAppVersion = storeRolloutPreviousAppVersion;
    }
    if (isFeatureFlagsTest) {
      test.type = TestType.FEATURE;
    }
    if (scheduledStartTime) {
      scheduledStartTime.setMilliseconds(0);
      scheduledStartTime.setSeconds(0);
      test.scheduledStartTime = scheduledStartTime.toISOString();
    }

    const response = testData ? await editTest(projectUID, testData.uid, test) : await createTest(projectUID, test);

    if (response.statusCode === 409) {
      setError('Test with this name already exist');
      return true;
    }

    if (response.message) {
      setError(Array.isArray(response.message) ? response.message.join(',') : response.message);
      return true;
    }

    onSavingDone();
    return true;
  };
  
  useEffect(() => {
    (async () => {
      const response = await getAppVersion(projectUID);
      if (response.version) {
        setAppVersion(response.version);
      }
      if (response.message) {
        setAppVersionError(Array.isArray(response.message) ? response.message.join('') : response.message);
      }
    })();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{testData ? 'Edit' : 'Create'} A/B test</DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.testInfoContainer}>
            <TestInfo name={name} setName={setName} />
          </div>
          <div>
            <CohortDetails cohort={cohort} countries={countries} setCohort={setCohort} />
          </div>
          <AppVersionDetails
            appVersionError={appVersionError}
            setUserType={setUserType}
            userType={userType}
            appVersion={appVersion}
            setAppVersion={setAppVersion}
          />
          <StartTimeDetails scheduledStartTime={scheduledStartTime} setScheduledStartTime={setScheduledStartTime} />
          {isFeatureFlagsTest && (
            <FieldList
              defaultValues={consts.defaultSegmentValues}
              onRemove={removeSegmentField}
              label={'Segment fields'}
              onAdd={addSegmentField}
              fieldsList={consts.SEGMENT_FIELDS}
              update={updateSegmentField}
              values={segment}
            />
          )}
          <RolloutForm
            storeRollout={storeRollout}
            setStoreRollout={setStoreRollout}
            storeRolloutPreviousAppVersion={storeRolloutPreviousAppVersion}
            setStoreRolloutPreviousAppVersion={setStoreRolloutPreviousAppVersion}
            storeRolloutTrafficShare={storeRolloutTrafficShare}
            setStoreRolloutTrafficShare={setStoreRolloutTrafficShare}
          />
          <Typography className={classes.typography} variant="subtitle1" component="h4">
            <b>{groups.length}</b> Groups
            <IconButton disabled={groups.length >= consts.GROUP_NAMES.length} onClick={addGroup}>
              <Add />
            </IconButton>
            <IconButton disabled={groups.length <= 2} onClick={removeLastGroup}>
              <Remove />
            </IconButton>
          </Typography>
          <GroupWeights groups={groups} setGroups={setGroups} />
          <div className={classes.groupsContainer}>
            {groups.map((group, i) => (
              <CreateAbTestGroup
                defaultValues={defaultFlags}
                withFeatureFlags={i !== 0 && isFeatureFlagsTest}
                key={i}
                group={group}
                setGroup={(g: Group) => updateGroup(g, i)}
                errors={flagErrors[group.name]}
              />
            ))}
          </div>
          <Button type="submit" className={classes.submit} color="primary" variant="contained">
            {testData ? 'Save' : 'Create'}
          </Button>
        </form>
        {error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : undefined}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  typography: {
    margin: '1rem 0',
  },
  submit: {
    margin: '1rem 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  groupsContainer: {
    marginBottom: '1rem',
  },
  testInfoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  rollout: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1rem',
  },
  rolloutItem: {
    marginRight: '1rem',
  },
});

export default CreateAbTestDialog;
