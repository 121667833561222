import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FunctionComponent } from 'react';

interface Props {
  setScheduledStartTime: (value: any) => void;
  scheduledStartTime: Date | null;
}
const StartTimeDetails: FunctionComponent<Props> = ({ setScheduledStartTime, scheduledStartTime }) => {
  const classes = useStyles();
  return (
    <div className={classes.startTimeContainer}>
      <FormControlLabel
        label={'Set scheduled start'}
        control={
          <Checkbox
            color="primary"
            checked={Boolean(scheduledStartTime)}
            onChange={(e) => {
              if (e.target.checked) {
                setScheduledStartTime(new Date());
              } else {
                setScheduledStartTime(null);
              }
            }}
          />
        }
      />
      {scheduledStartTime && <DateTimePicker value={scheduledStartTime} onChange={(e) => setScheduledStartTime(e)} />}
    </div>
  );
};

const useStyles = makeStyles({
  startTimeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '1rem',
  },
  btn: {
    marginRight: '1.5rem',
  },
});

export default StartTimeDetails;
