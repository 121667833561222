import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Cohort } from '../../../domain/tests';
import { Country } from '../../../domain/countries';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface Props {
  cohort: Cohort;
  countries: Country[];
  setCohort: (c: Cohort) => void;
}

const CohortDetails: FunctionComponent<Props> = (props) => {
  const { cohort, countries, setCohort } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.typography} variant="subtitle1" component="h4">
        Cohort details
      </Typography>
      <FormControlLabel
        label={'All countries'}
        control={
          <Checkbox color="primary" checked={cohort.allCountries} onChange={(e) => setCohort({ allCountries: e.target.checked, countryCodes: e.target.checked ? countries.map((c) => c?.countryCode) : [] })} />
        }
      />
      <Autocomplete
        options={countries}
        getOptionLabel={(option) => option?.name || ''}
        value={countries.filter((c) => cohort.countryCodes.includes(c.countryCode))}
        onChange={(_, value) => setCohort({ countryCodes: value.map((c) => c?.countryCode), allCountries: value.length === countries.length })}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?.name || ''}
          </li>
        )}
        limitTags={5}
        style={{ width: 500 }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disableCloseOnSelect
        multiple
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  typography: {
    marginTop: '1rem',
  },
});
export default CohortDetails;
