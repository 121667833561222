import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

function getRoot() {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost')) {
    return 'localhost:3000';
  }
  return `${hostname.replace('www.', '')}`;
}

export interface Error {
  message: string | string[];
  statusCode: number;
}

export async function request(endpoint: string, config: AxiosRequestConfig) {
  const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
  const root = getRoot();

  const token = Cookies.get('token');

  config.url = `${protocol}://${root}/api/v1${endpoint}`;
  config.validateStatus = () => true;
  config.withCredentials = true;
  config.timeout = 10000;
  config.headers = config.headers || {};
  config.headers['x-session-uuid'] = token;

  const response = await axios(config);
  if (response.status === 401 && !config.url.includes('/login')) {
    window.location.replace('/login');
  }
  return response;
}
