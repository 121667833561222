import { FunctionComponent, useCallback } from 'react';
import { styled } from '@material-ui/styles';
import { Slider, Typography } from '@material-ui/core';
import { Group } from '../../../domain/tests';

interface Props {
  groups: Group[];
  setGroups: (g: Group[]) => void;
}

const GroupWeights: FunctionComponent<Props> = (props) => {
  const { groups, setGroups } = props;

  const cumsum = groups.slice(0, -1).reduce((acc: number[], el) => {
    return [...acc, acc.length == 0 ? el.weight : acc.slice(-1)[0] + el.weight];
  }, []);

  const marks = groups.reduce((acc: any[], el, index) => {
    const value = index == 0 ? el.weight / 2 : acc[index - 1].value + groups[index - 1].weight / 2 + el.weight / 2;
    const mark = { value, label: `${el.name} ${(el.weight * 100).toFixed(2)}%` };
    return [...acc, mark];
  }, []);

  const updateGroups = useCallback(
    (values: number[]) => {
      const newGroups = [...groups];
      values = [...values, 1];
      for (let i = 0; i < values.length; i++) {
        newGroups[i].weight = i == 0 ? values[i] : values[i] - values[i - 1];
      }
      setGroups(newGroups);
    },
    [groups, setGroups],
  );

  return (
    <Container>
      {/* <WeightLabel>{weight}</WeightLabel> */}
      <Slider
        track={false}
        onChange={(e, value) => updateGroups(Array.isArray(value) ? value : [value])}
        step={0.01}
        value={cumsum}
        marks={marks}
        min={0}
        max={1}
        name="weight"
      />
      {/* <WeightLabel>{(1 - weight).toFixed(2)}</WeightLabel> */}
    </Container>
  );
};

const Container = styled('div')({
  //margin: '0 2rem',
});

const WeightLabel = styled('div')({
  minWidth: '2rem',
  textAlign: 'center',
});

export default GroupWeights;
