import { BetTime, FeatureName, Field, FieldType, Indicator, Strategy } from '../domain/flags/types';
import { SegmentName, UserRegType, UserType } from '../domain/segment/types';
import isoLanguages from 'iso-639-1';
import countryCodes from 'currency-codes';

const indicatorOptions = [Indicator.SUMMARY, Indicator.MACD, Indicator.STOCH, Indicator.BB, Indicator.RSI, Indicator.CCI, Indicator.CLOSE_PRICE];
const strategyOptions = [Strategy.FIXED, Strategy.CONSERVATIVE, Strategy.OPTIMAL, Strategy.AGGRESSIVE];
const languagesCodes = isoLanguages.getAllCodes();
const languages = isoLanguages.getLanguages(languagesCodes).map((lang) => ({label: lang.name, value: lang.code}));
const currencies = countryCodes.codes();

export default {
  GROUP_NAMES: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'G', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'],
  FEATURE_FIELDS: [
    {
      key: FeatureName.recommendedDeposit,
      type: FieldType.NUMBER,
      label: 'Recommended deposit'
    },
    {
      key: FeatureName.initialBetReal,
      type: FieldType.NUMBER,
      label: 'Initial Real bet amount'
    },
    {
      key: FeatureName.initialBetDemo,
      type: FieldType.NUMBER,
      label: 'Initial Demo bet amount'
    },
    {
      key: FeatureName.betTime,
      type: FieldType.SELECT,
      options: [BetTime.ONE, BetTime.FIVE, BetTime.FIFTEEN],
      label: 'Bet time'
    },
    // TODO: in v2
    // {
    //   key: FeatureName.assetType,
    //   type: FieldType.SELECT,
    //   options: [AssetType],
    // },
    {
      key: FeatureName.withAutoAssetSelect,
      type: FieldType.BOOLEAN,
      label: 'Auto asset selection'
    },
    {
      key: FeatureName.profitLimitAmount,
      type: FieldType.NUMBER,
      label: 'Profit limit amount'
    },
    {
      key: FeatureName.withProfitLimit,
      type: FieldType.BOOLEAN,
      label: 'Profit limit enabled (only for non-activated users)'
    },
    {
      key: FeatureName.defaultIndicator,
      type: FieldType.SELECT,
      options: indicatorOptions,
      label: 'Default indicator'
    },
    {
      key: FeatureName.blockedIndicators,
      type: FieldType.SELECT_MANY,
      options: indicatorOptions,
      label: 'Blocked indicators (only for non-activated users)'
    },
    {
      key: FeatureName.hiddenIndicators,
      type: FieldType.SELECT_MANY,
      options: indicatorOptions,
      label: 'Hidden indicators'
    },
    {
      key: FeatureName.indicatorsOrder,
      type: FieldType.SELECT_MANY,
      options: indicatorOptions,
      label: 'Indicators order'
    },
    {
      key: FeatureName.defaultStrategy,
      type: FieldType.SELECT,
      options: strategyOptions,
      label: 'Default strategy'
    },
    {
      key: FeatureName.blockedStrategies,
      type: FieldType.SELECT_MANY,
      options: strategyOptions,
      label: 'Blocked strategies (only for non-activated users)'
    },
    {
      key: FeatureName.hiddenStrategies,
      type: FieldType.SELECT_MANY,
      options: strategyOptions,
      label: 'Hidden strategies'
    },
    {
      key: FeatureName.strategiesOrder,
      type: FieldType.SELECT_MANY,
      options: strategyOptions,
      label: 'Strategies order'
    },
    {
      key: FeatureName.profitLimitAllowChange,
      type: FieldType.BOOLEAN,
      label: 'Allow user change profit limit (only for non-activated users)'
    },
  ] as Field[],
  SEGMENT_FIELDS: [
    {
      key: SegmentName.languages,
      type: FieldType.SELECT_MANY_WITH_LABELS,
      label: 'Languages',
      options: languages,
    },
    {
      key: SegmentName.currencies,
      type: FieldType.SELECT_MANY,
      label: 'Currencies',
      options: currencies,
    },
    {
      key: SegmentName.userRegType,
      label: 'Registration type',
      type: FieldType.SELECT,
      options: [
        {
          value: UserRegType.NEW_REG,
          label: 'New registration',
        },
        {
          value: UserRegType.PLATFORM_AUTH,
          label: 'Platform auth',
        },
        {
          value: UserRegType.REREG,
          label: 'Reregistrated',
        },
      ],
    },
    {
      key: SegmentName.isActivated,
      type: FieldType.BOOLEAN,
      label: 'Activated'
    },
  ],
  defaultSegmentValues: {
    [SegmentName.languages]: [languages[0].value],
    [SegmentName.currencies]: [currencies[0]],
    [SegmentName.userRegType]:  UserRegType.NEW_REG,
    [SegmentName.isActivated]: false,
  },
};
