import { FunctionComponent, useEffect, useState } from 'react';
import { TestDevice, findDevices } from '../../domain/testDevices';
import Device from './device';
import { List, Typography } from '@material-ui/core';
import ActionBar from './actionBar';

const DevicesList: FunctionComponent = () => {
  const [devices, setDevices] = useState<TestDevice[]>([]);

  useEffect(() => {
    updateDevicesListList();
  }, []);

  const updateDevicesListList = async () => {
    const response = await findDevices();
    if (!response.statusCode) {
      setDevices(response);
    }
  };

  return (
    <List>
      <Typography variant="caption" color="primary" style={{ paddingLeft: '16px' }}>
        Test devices does not affect counter
      </Typography>
      <ActionBar onDeviceCreated={updateDevicesListList} />
      {devices.map((d) => (
        <Device key={d.adID} device={d} onDeviceUpdate={updateDevicesListList} />
      ))}
    </List>
  );
};

export default DevicesList;
