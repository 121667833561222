import { FunctionComponent } from 'react';
import { FeatureFlags, FeatureName, Field } from '../../../domain/flags/types';
import AddNewField from './addField';
import FieldItem from './item';
import { makeStyles } from '@material-ui/core';
import { Segment } from '../../../domain/tests';
import { SegmentName } from '../../../domain/segment/types';
import { ErrorMap } from '../validateFlags';

export type SegmentFeatures = FeatureFlags & Segment;

interface Props {
  values: Partial<SegmentFeatures>;
  onAdd?: (field: string) => void;
  onRemove?: (field: string) => void;
  update: (field: string, value: any) => void;
  fieldsList: Field[];
  defaultValues?: Partial<SegmentFeatures>;
  disabled?: boolean;
  label?: string;
  errors?: ErrorMap
}

const FieldList: FunctionComponent<Props> = ({ values, onAdd, update, fieldsList, onRemove, defaultValues, errors, disabled = false, label = 'Rewrite fields for group' }) => {
  const keys = Object.keys(values) as FeatureName[] & SegmentName[];
  const classes = useStyles();
  const getFieldParameters = (key: string) => {
    return fieldsList.find((f) => f.key === key)!;
  };

  return (
    <div>
      <div className={classes.container}>{label}</div>
      {keys.map((field) => {
        const params = getFieldParameters(field);
				if (!params) {
					return null;
				}

        return (
          <FieldItem
            onRemove={onRemove}
            onChange={(newValue) => update(field, newValue)}
            type={params.type}
            options={params.options}
            value={values[field]}
            name={field}
            label={params.label}
            key={field}
            disabled={disabled}
            error={errors?.[field]}
          />
        );
      })}
      {keys.length !== fieldsList.length && onAdd && <AddNewField defaultValues={defaultValues} fieldsList={fieldsList.filter((field) => field.key in values === false)} onAdd={onAdd} />}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    paddingBottom: '1.5rem',
    paddingTop: '1.5rem',
    fontWeight: 'bold',
  },
});


export default FieldList;
