import { Container, Tab, Tabs, Toolbar, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../features/projectDetails';

const tabs = [
  {
    label: 'Regular',
    type: 'regular',
  },
  {
    label: 'Feature Flags',
    type: 'ff',
  },
  {
    label: 'Auto',
    type: 'auto',
  },
];

const TestTypesTabs: FunctionComponent = () => {
  const classes = useStyles();
  const params = useParams<RouterParams>();
  const history = useHistory();
  const handleTabClick = (type: string) => {
    history.push(`/projects/${params.uid}/${type}`);
  };

  return (
    <Container className={classes.body}>
      <Tabs
        centered
        value={params.type}
        className={classes.bar}
        classes={{
          indicator: classes.indicator
        }}
      >
        {tabs.map((tab) => (
          <Tab className={classes.link} key={tab.type} label={tab.label} value={tab.type} onClick={() => handleTabClick(tab.type)} />
        ))}
      </Tabs>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  body: {
    maxWidth: 1000,
  },
  bar: {
    padding: 0,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  link: {
    color: 'black',
    display: 'block',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));

export default TestTypesTabs;
