import { MenuItem, Select } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { MainProps } from '../useFieldComponent';

interface SelectProps extends MainProps<string, ChangeEvent<{ name?: string | undefined; value: unknown }>> {
  options: any[];
}

const SelectComponent = ({ value, onChange, disabled, options }: SelectProps) => {
  return (
    <Select disabled={disabled} fullWidth value={value} variant="outlined" onChange={onChange}>
      {options?.map((option) => {
        return (
          <MenuItem key={option?.value || option} value={option?.value || option}>
            {option?.label || option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectComponent;
