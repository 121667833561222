import { FunctionComponent } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Group, editTestStatus } from '../../../domain/tests';

interface Props {
  testId: string;
  group: Group;
  isTestNotFinished: boolean;
  isWon: boolean;
  participants: number;
  projectUID: string;
  onTestEdited: () => void;
}

const GroupView: FunctionComponent<Props> = (props) => {
  const { testId, projectUID, group, isWon, isTestNotFinished, participants, onTestEdited } = props;

  const classes = useStyles({ isWon });

  return (
    <div className={classes.groupContainer}>
      <Typography component="h5" variant="subtitle1">
        {group.name}{' '}
        <span className={classes.info}>
          {Math.ceil(group.weight * 100)}% auditory. Current {participants}
        </span>
      </Typography>
      <br />
      <Typography component="p" variant="body1">
        {group.description}
      </Typography>
      <div className={classes.winButtonContainer}>
        {isTestNotFinished && (
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              await editTestStatus(projectUID, testId, { status: 'finished', winGroupID: group.id });
              onTestEdited();
            }}
          >
            Group chosen
          </Button>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  groupContainer: (props: any) => ({
    background: props.isWon ? '#e8f5e9' : 'none',
    border: '1px solid #3c3c3c',
    padding: '1rem',
    borderRadius: '5px',
    margin: '1rem 0',
  }),
  winButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  info: {
    color: '#646363',
    float: 'right',
  },
});

export default GroupView;
