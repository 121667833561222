import { FunctionComponent } from 'react';
import Header from '../components/header';
import { Container, makeStyles } from '@material-ui/core';
import ProjectList from '../features/projectList';

const ProjectsPage: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <Container className={classes.body}>
        <ProjectList />
      </Container>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: 1000,
  },
}));

export default ProjectsPage;
