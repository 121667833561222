import { FunctionComponent, useState } from 'react';
import { TestDevice, updateDevice, deleteDevice } from '../../domain/testDevices';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import GroupSelect from './groupSelect';

interface Props {
  device: TestDevice;
  onDeviceUpdate: () => void;
}

const Device: FunctionComponent<Props> = (props) => {
  const { device, onDeviceUpdate } = props;

  const [group, setGroup] = useState(device.group);

  const updateGroup = async (newGroup: string) => {
    setGroup(newGroup);
    await updateDevice(device.adID, newGroup);
  };

  const removeDevice = async () => {
    await deleteDevice(device.adID);
    onDeviceUpdate();
  };

  return (
    <ListItem>
      <ListItemText primary={device.adID} />

      <GroupSelect group={group} setGroup={updateGroup}/>

      <ListItemSecondaryAction>
        <IconButton color="secondary" edge="end" aria-label="comments" onClick={removeDevice}>
          <DeleteForever />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default Device;
