import { FunctionComponent, useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Group } from '../../../domain/tests';
import FieldList from '../../flags/fields/list';
import consts from '../../../common/consts';
import { FeatureFlags, FeatureName } from '../../../domain/flags/types';
import { ErrorMap } from '../../flags/validateFlags';

interface Props {
  group: Group;
  setGroup: (g: Group) => void;
  withFeatureFlags?: boolean;
  defaultValues?: FeatureFlags;
  errors: ErrorMap
}

const CreateAbTestGroup: FunctionComponent<Props> = (props) => {
  const { group, setGroup, withFeatureFlags, defaultValues, errors } = props;

  const updateFlag = (field: string, value: any) => {
    const newGroup = { ...group, flags: { ...group.flags, [field]: value } };
    setGroup(newGroup);
  };
  const addFlag = (field: string, value?: any) => {
    updateFlag(field, value || null);
  };
  const removeFlag = (field: string) => {
    const newFields = { ...group.flags };
    delete newFields[field as FeatureName];
    const newGroup = { ...group, flags: { ...newFields } };
    setGroup(newGroup);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <b>Group {group.name} </b>
      {(group.weight * 100).toFixed(2)}%
      <TextField
        label={'Group description'}
        className={classes.textArea}
        variant="outlined"
        value={group.description}
        onChange={(e) => setGroup({ ...group, description: e.target.value })}
        fullWidth
      />
      {withFeatureFlags && (
        <FieldList
          defaultValues={defaultValues}
          onRemove={removeFlag}
          onAdd={addFlag}
          fieldsList={consts.FEATURE_FIELDS}
          update={updateFlag}
          values={group.flags || {}}
          errors={errors}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    padding: '0.5rem',
  },
  textArea: {
    minWidth: '15rem',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
});

export default CreateAbTestGroup;
