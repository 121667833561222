import { FunctionComponent } from 'react';
import Header from '../components/header';
import { Container, makeStyles } from '@material-ui/core';
import TestDeviceList from '../features/testDevicesList';

const DevicesPage: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <Container className={classes.body}>
        <TestDeviceList />
      </Container>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: 1000,
  },
}));

export default DevicesPage;
