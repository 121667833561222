import { SegmentName } from "../segment/types";

export enum BetTime {
  ONE = 1,
  FIVE = 5,
  FIFTEEN = 15,
}

/**
 * @ignore Will be implemented in v2
 */
export enum AssetType {}

export enum Strategy {
  AGGRESSIVE = 'agressive',
  FIXED = 'fixed',
  OPTIMAL = 'optimal',
  CONSERVATIVE = 'conservative',
}

export enum Indicator {
  RSI = 'rsi',
  BB = 'bb',
  MACD = 'macd',
  STOCH = 'stoch',
  SUMMARY = 'summary',
  CCI = 'cci',
  CLOSE_PRICE = 'close_price',
}

export interface FeatureFlags {
  recommendedDeposit: number;
  initialBetReal: number;
  initialBetDemo: number;
  betTime: BetTime;
  assetType: AssetType;
  withAutoAssetSelect: boolean;
  profitLimitAmount: number;
  withProfitLimit: boolean;
  defaultIndicator: Indicator;
  blockedIndicators: [Indicator];
  hiddenIndicators: [Indicator];
  indicatorsOrder: [Indicator];
  defaultStrategy: Strategy;
  blockedStrategies: [Strategy];
  hiddenStrategies: [Strategy];
  strategiesOrder: [Strategy];
  profitLimitAllowChange: boolean;
}

export enum FieldType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  SELECT_MANY = 'SELECT_MANY',
  SELECT_MANY_WITH_LABELS = 'SELECT_MANY_WITH_LABELS',
}

export enum FeatureName {
  recommendedDeposit = 'recommendedDeposit',
  initialBetReal = 'initialBetReal',
  initialBetDemo = 'initialBetDemo',
  betTime = 'betTime',
  assetType = 'assetType',
  withAutoAssetSelect = 'withAutoAssetSelect',
  profitLimitAmount = 'profitLimitAmount',
  withProfitLimit = 'withProfitLimit',
  defaultIndicator = 'defaultIndicator',
  blockedIndicators = 'blockedIndicators',
  hiddenIndicators = 'hiddenIndicators',
  indicatorsOrder = 'indicatorsOrder',
  defaultStrategy = 'defaultStrategy',
  blockedStrategies = 'blockedStrategies',
  hiddenStrategies = 'hiddenStrategies',
  strategiesOrder = 'strategiesOrder',
  profitLimitAllowChange = 'profitLimitAllowChange',
}

export interface Field {
  key: FeatureName | SegmentName;
  type: FieldType;
  options?: any[];
  label?: string;
}
