import { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel, Typography, TextField, Radio } from '@material-ui/core';
import { UserType } from '../../../domain/segment/types';

interface Props {
  appVersionError?: string;
  appVersion: string;
  setAppVersion: (v: string) => void;
  setUserType: (t: UserType) => void;
  userType: UserType
}

const AppVersionDetails: FunctionComponent<Props> = (props) => {
  const { appVersionError, appVersion, setAppVersion, setUserType, userType } = props;

  return (
    <div style={{ marginTop: '1rem' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          label={'Only new users'}
          control={<Radio color="primary" checked={userType === UserType.NEW} onChange={(e) => setUserType(UserType.NEW)} />}
        />
        <FormControlLabel
          label={'Only old users'}
          control={<Radio color="primary" checked={userType === UserType.OLD} onChange={(e) => setUserType(UserType.OLD)} />}
        />
        <FormControlLabel
          label={'All users'}
          control={<Radio color="primary" checked={userType === UserType.All} onChange={(e) => setUserType(UserType.All)} />}
        />
        {userType !== UserType.All ? (
          <TextField value={appVersion} placeholder="app version" onChange={(e) => setAppVersion(e.target.value as string)} />
        ) : undefined}
      </div>
      <Typography variant="caption" color="primary">
        Check if "Start version" in amplitude is bigger or equal then last detected version in appfollow. ONLY FOR MOBILE APPS.
      </Typography>
      <br />
      {appVersionError ? (
        <Typography variant="caption" color={'secondary'}>
          {`Automatic version detection error: ${appVersionError}`}
        </Typography>
      ) : undefined}
    </div>
  );
};

export default AppVersionDetails;
