import { IconButton, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { FieldType } from '../../../domain/flags/types';

import { DeleteForever } from '@material-ui/icons';
import { useFieldComponent } from './useFieldComponent';

interface FieldProps {
  value: any;
  name: string;
  label?: string;
  type: FieldType;
  options?: Array<{value: string, label: string}>;
  onChange: (value: any) => void;
  onRemove?: (field: string) => void;
  disabled: boolean;
  error?: string;
}

const FieldItem: FunctionComponent<FieldProps> = ({ value, name, type, options, onChange, onRemove, label, disabled, error }) => {
  const classes = useStyles();
  const { Component } = useFieldComponent({ type, value, onChange, options, disabled, error });
  return (
    <div className={classes.container}>
      <div className={classes.text}>{label || name}:</div>
      <div className={classes.field}>
        {Component}
        {onRemove && (
          <IconButton color="secondary" edge="end" aria-label="comments" onClick={() => onRemove(name)}>
            <DeleteForever />
          </IconButton>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    width: '15rem',
  },
  field: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'fit-content',
  },
}));

export default FieldItem;
