import { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import NotFound from './components/notFound';
import ProjectsPage from './pages/projects';
import ProjectPage from './pages/project';
import DevicesPage from './pages/devices';
import LoginPage from './pages/login';

const Router: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={'/'} component={() => <Redirect to={'/projects'} />} />
        <Route exact path={'/login'} component={() => <LoginPage />} />
        <Route exact path={'/projects'} component={() => <ProjectsPage />} />
        <Route exact path={'/projects/:uid'} component={() => <ProjectPage />} />
        <Route exact path={'/devices'} component={() => <DevicesPage />} />
        <Route exact path={`/projects/:uid/:type`} component={() => <ProjectPage />} />
        <Route path={'*'} component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
