import { Select, MenuItem, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { FeatureFlags, Field } from '../../../domain/flags/types';
import { SegmentFeatures } from './list';
import { SegmentName } from '../../../domain/segment/types';

interface AddNewFieldProps {
  onAdd: (field: string, value: any) => void;
  fieldsList: Field[];
  defaultValues?: Partial<SegmentFeatures>;
}

type FeatureFlagsKeys = keyof FeatureFlags

const AddNewField: FunctionComponent<AddNewFieldProps> = ({ onAdd, fieldsList, defaultValues }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Select
        renderValue={() => 'New field'}
        value={''}
        fullWidth
        onChange={(e) => {
          const newField = e.target.value as (FeatureFlagsKeys & SegmentName);
          const defaultValue = (defaultValues && defaultValues[newField]) ?? null;
          onAdd(newField, defaultValue);
        }}
      >
        {fieldsList.map((field, i) => (
          <MenuItem key={i} value={field.key}>
            {field.label || field.key}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
});

export default AddNewField;
