import { FunctionComponent, useState } from 'react';
import { Button, makeStyles, Input, Typography } from '@material-ui/core';
import { adminLogin } from '../../domain/admin';
import Cookies from 'js-cookie';

const LoginForm: FunctionComponent = () => {
  const classes = useStyles();
  const [error, setError] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmitForm = async () => {
    if (loading || !password) {
      return;
    }
    setError(false);
    setLoading(true);
    try {
      const result = await adminLogin(password);

      if (!result.token) {
        return setError(true);
      }

      Cookies.set('token', result.token, { expires: 7, path: '' });
      window.location.replace('/projects');
    } finally {
      setLoading(false);
    }
    return false;
  };

  return (
    <div className={classes.container}>
      <div className={classes.form} id="robots-dashboard-login">
        <Typography className={classes.label} variant="h6" component="h6">
          Login to dashboard
        </Typography>
        <Input
          className={classes.input}
          placeholder="Password"
          type="password"
          id="password"
          value={password}
          error={error}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.code == 'Enter') {
              onSubmitForm();
            }
          }}
        />
        <Button type="button" variant="contained" color="primary" disabled={loading} onClick={onSubmitForm}>
          Login
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginBottom: '16px',
  },
  input: {
    marginBottom: '16px',
  },
  form: {
    margin: '16px',
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default LoginForm;
