import { Checkbox, TextField, makeStyles } from '@material-ui/core';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete } from '@mui/material';
import { MainProps } from '../useFieldComponent';

interface SelectManyProps extends MainProps<any[], any[]> {
  options: Array<{ value: string; label: string }>;
}

const SelectManyComponent = ({ value, onChange, options, disabled }: SelectManyProps) => {
  const classes = useStyles();
  return (
    <Autocomplete
      fullWidth
      options={options!}
      getOptionLabel={(option) => option?.label || option || ''}
      value={value}
      onChange={(_, value) => {
        onChange(value);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.label || option || ''}
        </li>
      )}
      limitTags={options?.length}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      disableCloseOnSelect
      multiple
      className={classes.selectMany}
      readOnly={disabled}
      disabled={disabled}
    />
  );
};

const useStyles = makeStyles(() => ({
  selectMany: {
    minWidth: '14rem',
  },
}));

export default SelectManyComponent;
