import { FunctionComponent, useCallback, useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import { DeleteForever, Launch, Edit,  } from '@material-ui/icons';
import { Project, deleteProject } from '../../domain/projects';
import EditDialog from './editDialog';
import { useHistory } from 'react-router-dom';

interface Props {
  project: Project;
  onUpdateProjects: () => void;
}

const ProjectItem: FunctionComponent<Props> = (props) => {
  const { project, onUpdateProjects } = props;
  const classes = useStyles();
  const history = useHistory();

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const goToProject = useCallback(() => {
    const url = `/projects/${project.uid}/regular`;
    history.push(url);
  }, [history, project]);

  const handleDeleteClicked = async () => {
    setDeleteDialogIsOpen(false);
    await deleteProject(project.uid);
    onUpdateProjects();
  };

  return (
    <ListItem>
      <ListItemText primary={project.name} secondary={`slug: ${project.uid}`} />
      <ListItemSecondaryAction>
        <IconButton className={classes.action} color="secondary" edge="end" aria-label="comments" onClick={() => setDeleteDialogIsOpen(true)}>
          <DeleteForever />
        </IconButton>
        <IconButton className={classes.action} edge="end" aria-label="comments" onClick={() => setEditDialogIsOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton className={classes.action} edge="end" aria-label="comments" onClick={goToProject}>
          <Launch />
        </IconButton>
      </ListItemSecondaryAction>
      <EditDialog onRequestDone={onUpdateProjects} onClose={() => setEditDialogIsOpen(false)} open={editDialogIsOpen} project={project} />

      <Dialog onClose={() => setDeleteDialogIsOpen(false)} open={deleteDialogIsOpen}>
        <DialogContent dividers>
          <Typography gutterBottom>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteClicked} color="primary">
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  action: {
    marginLeft: '16px',
  },
}));

export default ProjectItem;
