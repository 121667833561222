import { Checkbox } from '@material-ui/core';
import { MainProps } from '../useFieldComponent';
import { ChangeEvent } from 'react';

interface CheckboxProps extends MainProps<boolean, ChangeEvent<HTMLInputElement>> {}

const CheckboxComponent = ({ value, onChange, disabled }: CheckboxProps) => {
    return <Checkbox disabled={disabled} checked={value} onChange={onChange} />;
};

export default CheckboxComponent;