import consts from '../../common/consts';
import { FeatureFlags, FieldType } from '../../domain/flags/types';
export interface ErrorMap {
  [key: string]: string;
}

export const validateFlags = (flags: FeatureFlags) => {
  const errors: ErrorMap = {};
  for (const key in flags) {
    const type = consts.FEATURE_FIELDS.find((f) => f.key === key)?.type;
    const value = flags[key as keyof FeatureFlags];
    if (type === FieldType.NUMBER) {
      const numberValue = Number(value);
      if (!numberValue || !Number.isInteger(numberValue) || numberValue <= 0) {
        errors[key] = 'Only positive integers allowed';
      }
    }
  }
  return errors;
};
