import { FunctionComponent, useState } from 'react';
import { ListItem, ListItemSecondaryAction, IconButton, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { createDevice } from '../../domain/testDevices';
import GroupSelect from './groupSelect';

interface Props {
  onDeviceCreated: () => void;
}

const ActionBar: FunctionComponent<Props> = (props) => {
  const { onDeviceCreated } = props;

  const [error, setError] = useState('');
  const [adID, setADID] = useState('');
  const [group, setGroup] = useState('A');

  const addDevice = async () => {
    if (!adID) {
      setError('Fill add id');
      return;
    }
    setError('');

    const response = await createDevice({ adID, group });
    if (response.statusCode) {
      const message = Array.isArray(response.message) ? response.message.join(', ') : response.message;
      setError(response.statusCode === 409 ? 'Device already exist' : message);
      return;
    }

    setADID('');
    onDeviceCreated();
  };

  return (
    <ListItem>
      <TextField
        label="AD ID *"
        value={adID}
        inputProps={{ maxLength: 200 }}
        style={{ width: 500, marginRight: '1rem' }}
        helperText={error}
        error={!!error}
        onChange={(e) => setADID(e.target.value.trim())}
        onKeyPress={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            addDevice();
          }
        }}
      />

      <GroupSelect group={group} setGroup={setGroup}/>

      <ListItemSecondaryAction>
        <IconButton color="primary" edge="end" aria-label="comments" onClick={addDevice}>
          <Add />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ActionBar;
