import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Project, getProjects } from '../../domain/projects';
import { List, makeStyles } from '@material-ui/core';
import ProjectItem from './projectItem';
import ActionBar from './actionBar';

const ProjectList: FunctionComponent = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState<Project[]>([]);

  const updateProjectList = useCallback(async () => {
    const projects = await getProjects();
    setProjects(projects);
  }, []);

  useEffect(() => {
    updateProjectList();
  }, [updateProjectList]);

  return (
    <List className={classes.container}>
      <ActionBar onProjectCreated={updateProjectList} />
      {projects.map((p, i) => (
        <ProjectItem key={p.id} project={p} onUpdateProjects={() => updateProjectList()} />
      ))}
    </List>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    // paddingTop: '24px',
  },
}));

export default ProjectList;
