import { TextField } from '@material-ui/core';
import { MainProps } from '../useFieldComponent';
import { ChangeEvent } from 'react';

interface InputProps extends MainProps<number, ChangeEvent<HTMLTextAreaElement | HTMLInputElement>> {}

const InputComponent = ({ value, onChange, disabled, error }: InputProps) => {
  return (
    <TextField
      error={Boolean(error)}
      InputProps={{ inputProps: { min: 1 } }}
      type="number"
      fullWidth
      variant="outlined"
      onChange={onChange}
      value={value}
      disabled={disabled}
      helperText={error}
    />
  );
};

export default InputComponent;
