import { AppBar, makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import DefaultTab from './tabs/default';

const Header: FunctionComponent = () => {
  const classes = useStyles();
  const isProd = window.location.host.includes('ppnet.systems');

  return (
    <AppBar position={'static'} className={isProd ? classes.appBarProd : ''}>
      <DefaultTab />
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarProd: {
    background: '#f44336',
  },
  body: {
    maxWidth: 1000,
  },
  bar: {
    padding: 0,
  },
  hint: {
    position: 'absolute',
    top: 0,
    right: 80,
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
    padding: '4px 16px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  link: {
    color: 'white',
    padding: '1.2rem',
    display: 'block',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  activeLink: {
    background: '#324191',
  },
  activeLinkProd: {
    background: '#aa2e25',
  },
}));

export default Header;
